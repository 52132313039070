import React, { useEffect, useState } from 'react';
import ReactDOM from "react-dom/client";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import config from '../../firebase-config.json';
import { LoadProductsAndPlans } from 'saas39.com/checkout';
var prefix = 'stripe/checkout';
var root = ReactDOM.createRoot(document.getElementById("plans"));
var start = function () {
    var firebaseApp = initializeApp(config);
    var auth = getAuth(firebaseApp);
    root.render(React.createElement(React.StrictMode, null,
        React.createElement(PlanCheckout, null)));
};
export var PlanCheckout = function () {
    var db = getFirestore();
    var _a = useState([]), products = _a[0], setProducts = _a[1];
    useEffect(function () {
        LoadProductsAndPlans().then(function (_a) {
            var plans = _a.plans, products = _a.products;
            // TODO: sort price by created_at descending
            plans.forEach(function (price) {
                var product = products.find(function (p) { return p.id == price.product; });
                product.prices[price.recurring.interval || 'month'] = price;
            });
            setProducts(products.sort(function (p1, p2) {
                if (p1.prices['month'].unit_amount < p2.prices['month'].unit_amount)
                    return -1;
                else
                    return 1;
            }));
        });
    }, [db]);
    if (products.length === 0) {
        return null;
    }
    else {
        return React.createElement(React.Fragment, null, products.map(function (p, i) {
            return React.createElement(Plan, { key: "plan-".concat(i), product: p, interval: 'month' });
        }));
    }
};
var Plan = function (_a) {
    var product = _a.product, interval = _a.interval;
    var price = product.prices[interval];
    return (React.createElement("div", { className: "col plan" },
        React.createElement("h3", null, product.name),
        React.createElement("p", { className: "price" },
            FormatCurrency(price.unit_amount),
            " / ",
            price.interval),
        product.role === 'premium' && (React.createElement("h4", null, "Coming Soon")),
        product.role === 'free' && (React.createElement("a", { className: "btn btn-lg btn-primary", href: "/join/".concat(price.id) }, price.unit_amount > 0 ? 'Buy now' : 'Get now')),
        React.createElement("div", { className: "divider" }),
        React.createElement("div", { className: "features" }, PlanDetails[product.role].map(function (feature, i) { return (React.createElement("div", { key: "feature-".concat(product.role, "-").concat(i) }, feature)); }))));
};
var PlanDetails = {
    'free': [
        'No credit card required',
        'Up to 5 events',
        'Up to 200 participants',
    ],
    'premium': [
        'Up to 50 events',
        'Up to 2000 participants',
        'Post anonymously',
    ],
};
var FormatCurrency = function (value) {
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return formatter.format(value / 100.0);
};
start();
